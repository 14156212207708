<template>
	<div id="project-wrap">
		<Header2022></Header2022>
		<full-page :options="options" ref="fullpage" v-if="list && list.length>0">
			<div class="section" v-for="(item,index) in list" :key="index">
				<div class="videoContainer" v-if="item.is_show_type==2">
					<video class="fullscreenVideo" id="bgVid" data-autoplay autoplay="autoplay" loop="loop"
						 muted="muted">
						<source :src="item.mp4_url">
					</video>
				</div>
				<template v-if="item.is_show_type==1">
					<img class="image" :src="item.image_max" v-if="browser=='Edge'">
					<iframe ref="iframe" :src="item.vr_url" frameborder="0" style="width: 100%;height: 100%;"
						v-else></iframe>
				</template>
				<img class="image" :src="item.image_max" v-if="item.is_show_type==0">
				<div class="headline">
					<div class="title">{{item.name}}</div>
					<div class="subtitle">{{item.sub_name}}</div>
				</div>
				<div class="btn-wrap animate__animated" :class="current==index?'animate__fadeInDown':''">
					<div class="btn" :class="btnItem.type" v-for="(btnItem,btnIndex) in item.btnList" :key="btnIndex"
						@click="btntap(btnItem.link,item.product_id)">{{btnItem.name}}</div>
				</div>
			</div>
		</full-page>
	</div>
</template>

<script>
	import utils from '@/common/utils'
	import Header2022 from '@/components/header2022.vue'
	import {
		mapState
	} from 'vuex'
	export default {
		components: {
			Header2022,
		},
		data() {
			return {
				options: {
					css3: true,
					scrollBar: true,
					afterLoad: this.afterLoad,
				},
				browser: '',
				current: 0,
				loop: true,
				iframeWin: {},
				list: [],
				type:'',
			}
		},
		watch: {
			// 监听事件，表单页面关闭时结束视频播放
			'visible': function(val) {
				if (this.visible === false) { //visible为表单显示状态
					let myVideo = document.getElementById('video') //对应video标签的ID
					myVideo.pause()
				}
			},
			'$route':{
			  handler: function (to) {
			    if(to.name == "project" && to.params.paramsName){
					this.list=[];
					this.type=to.params.paramsName;
					if(to.params.paramsName=='yard_online'){
						this.yardonlineData();
						return;
					}
					this.getData();
			    }
			  },
			  immediate: true
			}
		},
		created() {
			this.myBrowser();
		},
		beforeRouteLeave(to, from, next) {
			this.$refs.fullpage.api.destroy('all');
			next();
		},
		computed: {
			...mapState(['houseApi','mallApi'])
		},
		methods: {
			yardonlineData() {
				this.$axios.get(this.mallApi, {
						params: {
							ctl:'mallapi',
							act:'fitting_list'
						}
					})
					.then(res => {
						if (res.data.status == 1) {
							var arr = res.data.data;
							for (var item of arr) {
								item.btnList = [{
										name: '立即预定',
										type: 'reverse',
										link: {
											url: item.deal_url
											// pathName: 'houseCustom'
										}
									},
									{
										name: '小院在线',
										type: 'online',
										link: {
											url: item.deal_url
										}
									},
									{
										name: '了解更多',
										type: 'more',
										link: {
											url: item.deal_url
										}
									},
								]
							}
							this.list = arr;
						}
					})
			},
			getData() {
				this.$axios.get(this.houseApi + 'pc/house/type/product_list', {
						params: {
							type: this.type
						}
					})
					.then(res => {
						if (res.data.status == 1) {
							var arr = res.data.data.data;
							for (var item of arr) {
								item.btnList = [{
										name: '立即预定',
										type: 'reverse',
										link: {
											// url: item.to_url,
											pathName: 'houseConfig'
										}
									},
									{
										name: '小院在线',
										type: 'online',
										link: {
											url: item.to_url
										}
									},
									{
										name: '了解更多',
										type: 'more',
										link: {
											url: item.to_url
										}
									},
								]
							}
							this.list = arr;
							this.type='';
						}
					})
			},
			myBrowser() {
				var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
				var isOpera = userAgent.indexOf("Opera") > -1;
				if (isOpera) {
					this.browser = "Opera";
				}; //判断是否Opera浏览器
				if (userAgent.indexOf("Firefox") > -1) {
					this.browser = "FF";
				} //判断是否Firefox浏览器
				if (userAgent.indexOf("Chrome") > -1) {
					this.browser = "Chrome";
				}
				if (userAgent.indexOf("Safari") > -1) {
					this.browser = "Safari";
				} //判断是否Safari浏览器
				if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
					this.browser = "IE";
				};
				//判断是否IE浏览器
				if (userAgent.lastIndexOf("Trident") > -1) {
					this.browser = "Edge";
				}
			},
			sendMessage() {
				// 外部vue向iframe内部传数据
				this.iframeWin.postMessage({
					cmd: 'getFormJson',
					params: {}
				}, '*')
			},
			afterLoad: function(origin, destination, direction) {
				this.current = destination.index;
			},
			btntap: utils.debounce(function(e,id) {
				if (e.url) {
					window.open(e.url)
					return;
				}
				if (e.pathName == this.$route.name) {
					this.reload()
				}
				if (e.pathName) {
					// this.$store.commit('emptyHouse');
					this.$router.push({
						name: e.pathName,
						params: {
							id
						}
					});
				}
			}, 200, true),
		}

	}
	// }
</script>

<style lang="scss" scoped>
	.index-wrap {
		overflow-x: hidden;
		overflow-y: hidden;

	}

	.videoContainer {
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -100;
	}

	#bgVid {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}

	.videoContainer:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(25, 29, 34, .65);
	}

	#project-wrap {
		overflow-x: hidden;
		overflow-y: hidden;

		.section {
			position: relative;

			.image-wrap {
				height: 100%;
				width: 100%;
			}

			.el-image__inner--center {
				top: 0 !important;
				left: 0 !important;
				transform: translate(0%, 0%) !important;
			}

			.image {
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
			.headline{
				position: absolute;
				top: 246px;
				width: 100%;
				text-align: center;
				color: #FFFFFF;
				.title{
					font-size: 68px;
					line-height: 68px;
					font-weight: bold;
					margin-bottom: 21px;
				}
				.subtitle{
					font-size: 34px;
					line-height: 34px;
					font-weight: 300;
				}
			}
			.btn-wrap {
				position: absolute;
				bottom: 100px;
				width: 100%;
				display: flex;
				justify-content: center;

				.animate__animated {
					&.animate__fadeInDown {
						--animate-duration: 2s;
					}
				}

				.btn {
					width: 256px;
					height: 40px;
					border-radius: 20px;
					font-size: 12px;
					line-height: 40px;
					text-align: center;
					margin: 0 20px;
					cursor: pointer;

					&.online {
						background: #222222;
						color: #FFFFFF;
					}

					&.more {
						background: #FFFFFF;
						color: #393C41;
					}

					&.reverse {
						background: #FFFFFF;
						color: #393C41;
					}
				}
			}
		}

	}
</style>
